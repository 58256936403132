import React, { useEffect, useState } from 'react';
import { Grid, Grow, Box, Typography, useTheme } from '@mui/material';
import axios from 'axios';
// components
import AuraText from '../../assets/images/text.svg';
import PackCard from '../PackCard/PackCard';
import ScheduleCalendar from '../ScheduleCalendar/ScheduleCalendar';
import Copyright from '../Copyright/Copyright';
import Carousel from './Carousel';
// Images
import Studio1 from '../../assets/images/studio_1.jpg';
import Studio2 from '../../assets/images/studio_2.jpg';
import Studio3 from '../../assets/images/studio_3.jpg';
import Studio4 from '../../assets/images/studio_4.jpg';
import Studio5 from '../../assets/images/studio_5.jpg';
import Studio6 from '../../assets/images/studio_6.jpg';
import Studio7 from '../../assets/images/studio_7.jpg';
import Studio8 from '../../assets/images/studio_8.jpg';

// Check for dark mode
const usePrefersColorScheme = () => {
    const [isDarkMode, setIsDarkMode] = useState(false);

    // Check for dark mode on load
    useEffect(() => {
        const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        setIsDarkMode(mediaQuery.matches);

        const handleChange = (event) => {
            setIsDarkMode(event.matches);
        };

        mediaQuery.addEventListener('change', handleChange);
        return () => mediaQuery.removeEventListener('change', handleChange);
    }, []);

    return isDarkMode;
};

// Landing component

export default function Landing(props) {
    const [packs, setPacks] = useState([]);
    const isDarkMode = usePrefersColorScheme();
    const [selectedPack, setSelectedPack] = useState(null);
    const [selectedClass, setSelectedClass] = useState(null);

    // images array
    const images = [Studio1, Studio2, Studio3, Studio4, Studio5, Studio6, Studio7, Studio8];

    // Theme load
    const theme = useTheme();

    useEffect(() => {
        // Obtain available packs on load
        getPacks();
    }, []);

    useEffect(() => {
        // Add parallax effect to main carousel
        const handleScroll = () => {
            const carousel = document.getElementById('carousel-container');
            const scrollPosition = window.pageYOffset;

            carousel.style.transform = `translateY(${scrollPosition * 0.5}px)`;
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    // Hear for changes in the selected class or pack
    useEffect(() => {
        if ((selectedClass || selectedPack) && props.logguedInUser) {
            props.setView('schedule');
        } else if ((selectedClass || selectedPack) && !props.logguedInUser) {
            props.setView('login');
        }
    }, [selectedClass, selectedPack]);

    const getPacks = async () => {
        // Function retrieves packs from DB and add it to state
        await axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/pack/getAll`)
            .then((res) => {
                setPacks(res.data.result);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    return (
        <> 
            <Grow in={(props.view == 'landing')}>
                <Box
                    sx={{ zIndex: -1}}
                >
                    {/* Carousel */}
                    {images.length >=5 &&
                        <div style={{ marginTop: '-65px'}}>
                            <Carousel images={images}/>
                        </div>
                    }
                </Box>
            </Grow>
            <Grow in={(props.view == 'landing')}>
                <Box
                    sx={{
                        position: 'absolute',
                        zIndex: 1,
                        width: '100%',
                        height: '100%',
                        backgroundColor: theme.palette.background.paper,
                        backgroundImage: (theme.palette.mode == 'dark') ? `linear-gradient(to bottom, ${theme.palette.background.paper}, #2b2927)` : '#FFF',
                        opacity: 0.9,
                    }}
                >
                    {/* Landing page text */}
                    <Grid 
                        container 
                        alignItems={'center'}
                        justifyContent={'center'}
                        direction='column'
                        sx={{
                            width: {
                                xs: '90vw',
                                sm: '80vw',
                                md: '60vw',
                                lg: '50vw'
                            },
                            margin: '0 auto',
                            marginTop: '30px'
                        }}
                    >
                        <Grid item xs={12} style={{ width: '100%' }}>
                            <img
                                src={AuraText}
                                alt='aura-text'
                                style={{
                                    height: '50px',
                                    width: '100%',
                                    filter: isDarkMode ? 'brightness(300%) contrast(101%)' : 'brightness(0%) contrast(101%)'
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} style={{ width: '100%'}}>
                            <Typography
                                style={{
                                    fontFamily: 'Qairo',
                                    fontSize: '30px',
                                    width: '100%',
                                    textAlign: 'left',
                                    borderBottom: '1px solid',
                                    marginTop: 20,
                                    borderColor: (theme.palette.mode == 'dark') ? '#FFF' : '#000'
                                }}
                            >
                                noun. /âu - rä /
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ width: '100%' }}>
                            <Typography
                                style={{
                                    fontFamily: 'PangramRegular',
                                    fontStyle: 'italic',
                                    fontSize: '20px',
                                    textAlign: 'left',
                                }}
                            >
                                "Aura: El lugar donde encuentras equilibrio, conectas tu espíritu y descubres la paz interior."
                            </Typography>
                        </Grid>
                    </Grid>
                    {/* Class packs */}
                    <Typography
                        style={{
                            marginTop: '30px',
                            marginBottom: '30px',
                            fontFamily: 'Qairo',
                            fontSize: '30px',
                            fontWeight: 'bold'
                        }}
                    >
                        Paquetes de paz
                    </Typography>
                    <div>
                        <svg className="arrows">
                            <path className="a1" d="M0 0 L30 32 L60 0"></path>
                            <path className="a2" d="M0 20 L30 52 L60 20"></path>
                            <path className="a3" d="M0 40 L30 72 L60 40"></path>
                        </svg>
                    </div>
                    <Grid 
                        container 
                        direction={'row'} 
                        spacing={2} 
                        sx={{
                            width: '90%', 
                            margin: '0 auto', 
                            justifyContent: 'center', 
                            alignItems: 'center'
                        }}
                    >
                        {packs && packs.map((pack) => (
                            <Grid 
                                xs 
                                item
                                minWidth={'300px'}
                                sx={{ marginRight: '25px' }}
                            >
                                <PackCard pack={pack} isDarkMode={isDarkMode} setSelectedPack={setSelectedPack}/>
                            </Grid>
                        ))}
                    </Grid>
                    {/* Schedule */}
                    <Typography
                        style={{
                            marginTop: '100px',
                            marginBottom: '50px',
                            marginLeft: '30px',
                            marginRight: '30px',
                            fontFamily: 'Qairo',
                            fontSize: '30px',
                            fontWeight: 'bold',
                        }}
                    >
                        Horarios para despejarte hoy
                    </Typography>
                    <Grid 
                        container 
                        direction={'column'} 
                        spacing={2} 
                        sx={{
                            width: '90%', 
                            margin: '0 auto', 
                            justifyContent: 'center', 
                            alignItems: 'center'
                        }}
                    >
                        <ScheduleCalendar isDarkMode={isDarkMode} setSelectedClass={setSelectedClass} coaches={props.coaches} logguedInUser={props.logguedInUser}/>
                    </Grid>
                    <Copyright/>
                </Box>
            </Grow>
        </>
    )
}