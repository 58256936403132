import React, { useEffect } from 'react';
import consts from '../consts/consts';
import { 
    Grid, 
    FormControl, 
    InputLabel, 
    Select, 
    MenuItem, 
    Typography, 
    TextField, 
    FormControlLabel, 
    Switch, 
    RadioGroup, 
    Radio, 
    FormLabel,
    Autocomplete
} from '@mui/material';
// MUI-X Components
import { TimePicker, DatePicker } from '@mui/x-date-pickers';
import ProfilePicture from '../components/ProfilePicture/ProfilePicture';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

dayjs.extend(utc);
dayjs.extend(timezone);

const renderField = (field, formData, handleChange, errors, handleAutocompleteChange, options, excludedKeys, coaches, adminView) => {
    const fieldKey = field[0];
    const fieldValue = field[1];

    // Get coaches array
    let getCoachesArray = () => {
        let coachesArray = [];
        coaches.forEach(coach => {
            coachesArray.push({ displayValue: `${coach.firstName} ${coach.lastName}`, value: coach._id });
        });
        return coachesArray;
    }

    // Get options for select fields
    const getOptions = (fieldName) => {
        switch(fieldName) {
            case 'shoeSize': return consts.shoeSizes;
            case 'birthdayMonthNumber': return consts.months;
            case 'month' : return consts.months;
            case 'purchaseMonthNumber' : return consts.months;
            case 'status' : return consts.statuses;
            case 'discipline' : return consts.disciplines;
            case 'teacherID' : return getCoachesArray();
        }
    }

    // Check for excluded keys, if found, don't show anything (no field available)
    if (excludedKeys.includes(fieldKey)) {
        return null;
    }

    // Check for type of input
    if (fieldValue.subtype === 'select') {
        // Code to retrieve Select element
        let options = fieldValue.options ? fieldValue.options : getOptions(fieldKey);
        return (
            <Grid item xs={12} sm={6} key={fieldKey}>
                <FormControl fullWidth>
                    <InputLabel id={`${fieldKey}-label`}>{fieldValue.readableValue}</InputLabel>
                    <Select
                        labelId={`${fieldKey}-label`}
                        id={fieldKey}
                        value={formData[fieldKey] || ''}
                        label={fieldValue.readableValue}
                        onChange={(e) => handleChange(fieldKey, e.target.value)}
                    >
                        {options && options.map((option, index) => (
                            <MenuItem key={index} value={option.value}>{option.displayValue}</MenuItem>
                        ))}
                    </Select>
                    {errors[fieldKey] && <Typography color="error">{errors[fieldKey]}</Typography>}
                </FormControl>
            </Grid>
        );
    } else if (fieldValue.subtype === 'date') {
        // Code to retrieve Date element
        return (
            <Grid item xs={12} sm={6} key={fieldKey}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label={fieldValue.readableValue}
                        value={formData[fieldKey] ? dayjs(formData[fieldKey]).tz('America/Mexico_City') : null}
                        sx={{ width: '100%' }}
                        onChange={(newValue) => handleChange(fieldKey, newValue)}
                        renderInput={(params) => <TextField {...params} />}
                        format="DD/MM/YYYY"
                        timezone="America/Mexico_City"
                        views={['year', 'month', 'day']}
                        openTo={'year'}
                        maxDate={fieldKey === 'birthday' ? dayjs(new Date()).tz('America/Mexico_City') : null}
                    />
                </LocalizationProvider>
            </Grid>
        );
    }  else if (fieldValue.subtype === 'time') {
        // Code to retrieve Time element
            return (
                <Grid item xs={12} sm={6} key={fieldKey}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                            label={fieldValue.readableValue}
                            value={formData[fieldKey] ? dayjs(formData[fieldKey]).tz('America/Mexico_City') : null}
                            sx={{width: '100%'}}
                            timezone="America/Mexico_City"
                            onChange={(newValue) => handleChange(fieldKey, newValue)}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </Grid>
            );
        } else if (fieldValue.subtype === 'boolean') {
        // Code to retrieve Boolean element
        return (
            <Grid item xs={12} sm={6} key={fieldKey}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={formData[fieldKey] || false}
                            onChange={(e) => handleChange(fieldKey, e.target.checked)}
                            name={fieldKey}
                            color="primary"
                            defaultValue={false}
                        />
                    }
                    label={fieldValue.readableValue}
                />
                {errors[fieldKey] && <Typography color="error">{errors[fieldKey]}</Typography>}
            </Grid>
        );
    } else if (fieldValue.subtype === 'number') {
        // Code to retrieve Number element
        return (
            <Grid item xs={12} sm={6} key={fieldKey}>
                <TextField 
                    id={fieldKey}
                    label={fieldValue.readableValue}
                    variant="standard"
                    fullWidth
                    value={formData[fieldKey]}
                    onChange={(e) => handleChange(fieldKey, (fieldKey === 'creditsAll' || fieldKey === 'creditsPilates' || fieldKey === 'creditsSculpt') ?  Number(e.target.value) : e.target.value)}
                    error={!!errors[fieldKey]}
                    helperText={errors[fieldKey]}
                    type={(fieldKey === 'creditsAll' || fieldKey === 'creditsPilates' || fieldKey === 'creditsSculpt') ? 'number' : 'text'}
                    inputProps={{ min: 0 }}
                />
            </Grid>
        );
    } else if (fieldValue.subtype === 'autocomplete') {
        // Code to retrieve Autocomplete element
        return (
            <Grid item xs={12} sm={6} key={fieldKey}>
                <Autocomplete
                    id={fieldKey}
                    options={options}
                    getOptionLabel={(option) => option.name}
                    style={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label={fieldValue.readableValue} variant="outlined" />}
                    onInputChange={(event, newInputValue) => {
                        handleAutocompleteChange(fieldKey, newInputValue);
                    }}
                />
            </Grid>
        );
    } else if (fieldValue.subtype === 'radio') {
        // Code to retrieve Radio element
        return (
            <Grid item xs={12} sm={6} key={fieldKey}>
                <FormControl>
                    <FormLabel id={`${fieldKey}-radio`}>{fieldValue.readableValue}</FormLabel>
                    <RadioGroup
                        aria-labelledby={`${fieldKey}-radio-group-label`}
                        defaultValue="female"
                        name={`${fieldKey}-radio-buttons-group`}
                        onChange={(e) => handleChange(fieldKey, e.target.value)}
                    >
                        {
                            fieldValue.options.map((option, index) => (
                                <FormControlLabel 
                                    key={index} 
                                    value={option.value} 
                                    checked={formData[fieldKey] === option.value}
                                    control={<Radio />} 
                                    label={option.displayValue} 
                                />
                            ))
                        }
                    </RadioGroup>
                </FormControl>
                {errors[fieldKey] && <Typography color="error">{errors[fieldKey]}</Typography>}
            </Grid>
        );
    } else if (fieldValue.subtype === 'image') {
        // Code to retrieve image element
        return (
            <ProfilePicture
                key={fieldKey}
                userData={formData}
                postAction={(base64image) => handleChange(fieldKey, base64image)}
                type={(adminView === 'user' ? 'user' : 'coach')}
            />
        )
    } else {
        // Code to retrieve Text element
        return (
            <Grid item xs={12} sm={6} key={fieldKey}>
                <TextField
                    id={fieldKey}
                    label={fieldValue.readableValue}
                    variant="standard"
                    fullWidth
                    value={formData[fieldKey] || ''}
                    onChange={(e) => handleChange(fieldKey, e.target.value)}
                    error={!!errors[fieldKey]}
                    helperText={errors[fieldKey]}
                />
            </Grid>
        );
    }
}

export default renderField;