import React, { useState, useEffect } from 'react';
import { 
    Modal, 
    Backdrop, 
    Fade, 
    Box, 
    Grid, 
    Button, 
    IconButton, 
    Paper, 
    Checkbox, 
    Typography,
    TextField,
    useTheme,
    Tabs,
    Tab
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import CIcon from "@coreui/icons-react";
import * as icon from '@coreui/icons';
import DialogSports from '../Dialog/Dialog';
import SwipeableViews from "react-swipeable-views";

const PeopleModal = (props) => {
    const [searchText, setSearchText] = useState('');
    const [users, setUsers] = useState([]);
    const [participants, setParticipants] = useState([]);
    const [invitees, setInvitees] = useState([]);
    const [classCapacity, setClassCapacity] = useState(0);
    const [value, setValue] = useState(0);
    const [limitReached, setLimitReached] = useState(false);
    const [dialogProps, setDialogProps] = useState({ title: '', content: '', leftButton: '', rightButton: '', open: false });
    const theme = useTheme();

    // Handle Left button of dialog
    const handleDialogRightButton = () => {
        setDialogProps(
            { 
                title: '', 
                content: '', 
                leftButton: '', 
                rightButton: '', 
                open: false
            }
        );
        props.getData();
        handleClose();
    }

    // Use effect to check if the class is full
    useEffect(() => {
        if ((participants.length + invitees.length) >= classCapacity) {
            setLimitReached(true);
        } else {
            setLimitReached(false);
        }
    }, [participants, invitees]);

    // Use effect obtain all available users
    useEffect(() => {
        if (props.classEdit != null) {
            getAllUsers();
            retrieveParticipants();
            setClassCapacity(props.classEdit.classCapacity);
        }
    }, [props.classEdit]);

    // Set participants from selected class
    const retrieveParticipants = () => {
        setParticipants(props.classEdit.participantIDS);
        setInvitees(props.classEdit.invitees);
    }

    // Fetch available users from API function
    const getAllUsers = async () => {
        await axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/user/getAll`)
        .then(response => {
            setUsers(response.data.result);
        })
        .catch(error => console.error(error));
    }

    // Function to handle user selection
    const handleUserChange = (userID) => {
        if (participants.includes(userID)) {
            setParticipants(participants.filter((participantID) => participantID !== userID));
        } else {
            (!limitReached) && setParticipants([...participants, userID]);
        }
    };

    // Function to handle invitee selection
    const handleInviteeChange = (inviteeID) => {
        
        if (invitees.some(invitee => invitee._id === inviteeID)) {
            setInvitees(invitees.filter((invitee) => invitee._id !== inviteeID));
        } else {
            (!limitReached) && setInvitees([...invitees, { _id: inviteeID }]);
        }
    };

    // Function to handle search bar input
    const handleSearch = (query) => {
        setSearchText(query);
        // Focus on the search input
        document.getElementById('people-modal-search-input').focus();
    };

    // Filter available users based on search query
    let filteredUsers = users.filter(item => {
        return Object.values(item).some(value =>
            (value != null && value != undefined) ? value.toString().toLowerCase().includes(searchText.toLowerCase()) : false
        );
    });

    // Handle modal close
    const handleClose = () => {
        props.setClassEdit(null);
    };

    // Handle tab change
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    // Custom tab panel component
    const CustomTabPanel = (props) => {
        const { children, value, index, ...other } = props;
        return (
            <Box
                role="tabpanel"
                hidden={value !== index}
                id={`credits-type-tabpanel-${index}`}
                aria-labelledby={`credits-type-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box>
                        {children}
                    </Box>
                )}
            </Box>
        );
    }

    // Initial avatar JSX
    const InitialAvatar = (firstName) => {

        let firstletter = (firstName.firstName != undefined || firstName.firstName != null) ? firstName.firstName.charAt(0) : 'U';
        return (
            <Box
                sx={{
                    bgcolor: 'primary.main',
                    color: 'white',
                    width: 40,
                    height: 40,
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Typography>{firstletter}</Typography>
            </Box>
        )
    }

    // Handle update participantIDS from selected class
    const updateParticipants = () => {
        axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/schedule/update`, {
            _id: props.classEdit._id,
            participantIDS: participants,
            invitees: invitees
        })
        .then(response => {
            setDialogProps(
                { 
                    title: 'Información ❗️', 
                    content: 'Participantes actualizados correctamente', 
                    rightButton: 'Ok', 
                    open: true
                }
            );
        })
        .catch(error => {
            setDialogProps(
                { 
                    title: 'Error ❗️', 
                    content: 'Ocurrió un error al actualizar los participantes', 
                    rightButton: 'Ok', 
                    open: true
                }
            );
        });
    }

    // Name option to add JSX
    const NameOption = (user) => {
        return (
            <Box>
                <Button
                    sx={{
                    width: '90%',
                    maxHeight: '55vh',
                    margin: 0.2,
                    padding: 0,
                    textTransform: 'none',
                    textAlign: 'left',
                    textOverflow: 'ellipsis',
                    overflow: 'scroll',
                    '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.1)', // Modify the background color when hovering
                    },
                    }}
                    onClick={() => handleUserChange(user.user._id)}
                >
                    <Grid container direction={'row'} alignItems="center"> {/* Add alignItems="center" to vertically align the items */}
                        <Grid item xs={2}>
                            <Checkbox checked={participants.includes(user.user._id)} disabled={limitReached}/>
                        </Grid>
                        <Grid item xs={2}>
                            {user.user.pictureURL ? (
                            <img src={user.user.pictureURL} alt={'user-picture'} style={{width: 40, height: 40, borderRadius: '50%'}}/>
                            ) : (
                            <InitialAvatar firstName={user.user.firstName} />
                            )}
                        </Grid>
                        <Grid item xs={6}>
                            <Typography sx={{ alignItems: 'center' }}>{`${user.user.firstName} ${user.user.lastName}`}</Typography>
                        </Grid>
                    </Grid>
                </Button>
            </Box>
        );
    }

    // Name option to remove JSX
    const NameParticipant = (userID) => {

        let user = users.find(user => user._id === userID.user);
        
        return (
            <Box sx={{width: '100%', display: 'block', margin: '10px 0px'}}>
                <Grid container direction={'row'} alignItems="center">
                    <Grid item xs={2}>
                        {(user && user.pictureURL) ? (
                        <img src={user.pictureURL} alt={'user-picture'} style={{width: 40, height: 40, borderRadius: '50%'}}/>
                        ) : (
                        <InitialAvatar firstName={user ? user.firstName : 'Usuario'} />
                        )}
                    </Grid>
                    <Grid item xs={8}>
                        <Typography sx={{ display: 'flex', alignItems: 'center', marginLeft: '10px'}}>
                            {`${user ? user.firstName : 'Usuario'} ${user ? user.lastName : 'Desconocido'}`}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton variant="contained" onClick={() => handleUserChange(user._id)}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </Box>
        );
    }

    // Name option to remove JSX
    const NameInvitee = (invitee) => {
        let inv = invitee.invitee;
        return (
            <Box sx={{width: '100%', display: 'block', margin: '10px 0px'}}>
                <Grid container direction={'row'} alignItems="center">
                    <Grid item xs={2}>
                        <InitialAvatar firstName={inv ? inv.firstName : 'Invitado'} />
                    </Grid>
                    <Grid item xs={8}>
                        <Typography sx={{ display: 'flex', alignItems: 'center', marginLeft: '10px'}}>
                            {`${inv ? inv.firstName : 'Usuario'} ${inv ? inv.lastName : 'Desconocido'}`}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton variant="contained" onClick={() => handleInviteeChange(inv._id)}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </Box>
        );
    }


    return (
        <Modal
            open={props.classEdit}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
        >
            <Fade in={props.classEdit}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 2,
                    width: {xs: '100vw', sm: '90vw', md: '80vw'},
                    height: {xs: '100vh', sm: '90vh', md: '80vh'},
                    overflowY: 'auto'
                }}>
                    <DialogSports dialogProps={dialogProps} setDialogProps={setDialogProps} handleDialogRightButton={handleDialogRightButton}/>
                    <Grid 
                        container 
                        spacing={0} 
                        direction='column'
                        display={'flex'}
                        height={'100%'}
                    >
                        {/* Title and close button */}
                        <Grid item xs={1}>
                            <Grid container direction={'row'}>
                                <Grid item xs>
                                    <Typography variant="h6">
                                        {`Participantes de la clase (${(props.classEdit ? participants.length : 0) + (props.classEdit ? invitees.length : 0)}/${(props.classEdit ? props.classEdit.classCapacity : 0)})`}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <IconButton variant="contained" onClick={handleClose}>
                                        <CloseIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* Participants */}
                        <Grid item xs={10}>
                            {/* Tabs */}
                            <Box>
                                <Tabs
                                    value={value}
                                    onChange={handleTabChange}
                                    aria-label="credits-type-tabs"
                                    variant="fullWidth"
                                >
                                    <Tab label={`Clientes (${participants.length})`} sx={{fontSize: '15px', textTransform: 'none'}}/>
                                    <Tab label={`Invitados (${invitees.length})`} sx={{fontSize: '15px', textTransform: 'none'}}/>
                                </Tabs>
                            </Box>
                            {/* Swipeable Views */}
                            <SwipeableViews
                                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                index={value}
                                onChangeIndex={(index) => setValue(index)}
                            >
                                {/* My Packages (client wants to name them membresias) */}
                                <CustomTabPanel value={value} index={0} dir={theme.direction}>
                                    <Grid 
                                        container 
                                        spacing={{xs: 0, sm: 1, md: 2}} 
                                        direction='row' 
                                        display={'flex'} 
                                        height={'100%'}
                                    >
                                        {/* Clickable list of elements of available users in DB */}
                                        <Grid item xs={5}>
                                            <Paper
                                                sx={{
                                                    p: 0,
                                                    height: {xs: '60vh', sm: '60vh', md: '50vh'},
                                                    overflowY: 'scroll',
                                                }}
                                            >
                                                
                                                <Box sx={{ display: 'flex', alignItems: 'flex-end', width: '90%', m: 0.2}}>
                                                    <CIcon icon={icon.cilMagnifyingGlass} style={{width: '25px', margin: '10px 10px', color: 'action.active'}}></CIcon>
                                                    <TextField 
                                                        id="people-modal-search-input" 
                                                        label="Buscar ..." 
                                                        variant="standard" 
                                                        fullWidth
                                                        value={searchText}
                                                        onChange={(e) => handleSearch(e.target.value)}
                                                    />
                                                </Box>
                                                {filteredUsers.map((user) => (
                                                    <NameOption user={user}/>
                                                ))}
                                            </Paper>
                                        </Grid>
                                        {/* Participants list */}
                                        <Grid item xs={7}>
                                            <Paper
                                                sx={{
                                                    p: 1,
                                                    height: {xs: '60vh', sm: '60vh', md: '50vh'},
                                                    overflowY: 'scroll',
                                                    backgroundColor: 'secondary.main',
                                                    display: (participants.length === 0) ? 'flex' : 'block',
                                                    textAlign: (participants.length === 0) ? 'center' : 'initial',
                                                    justifyContent: (participants.length === 0) ? 'center' : 'initial',
                                                    alignItems: (participants.length === 0) ? 'center' : 'initial',
                                                }}
                                            >
                                                {participants.length === 0 ? 
                                                    <Typography>Parece que esta clase está vacía, los usuarios se pueden anotar por su cuenta o puedes hacerlo tú mism@ eligiendo los clientes del lado izquierdo, Nota: No se descontarán créditos si se hace de manera manual</Typography>
                                                :
                                                <>
                                                <Typography>{`${participants.length + invitees.length} / ${classCapacity} lugares utilizados`}</Typography>
                                                {
                                                    (users.length > 0) && participants.map((user) => (
                                                        <NameParticipant user={user}/>
                                                    ))  
                                                }
                                                </>
                                                }
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                </CustomTabPanel>
                                <CustomTabPanel value={value} index={1} dir={theme.direction}>
                                    <Grid 
                                        container 
                                        spacing={{xs: 0, sm: 1, md: 2}} 
                                        direction='row' 
                                        display={'flex'} 
                                        height={'100%'}
                                    >
                                        {/* Participants list */}
                                        <Grid item xs={12}>
                                            <Paper
                                                sx={{
                                                    p: 1,
                                                    height: {xs: '60vh', sm: '60vh', md: '50vh'},
                                                    overflowY: 'scroll',
                                                    backgroundColor: 'secondary.main',
                                                    display: (participants.length === 0) ? 'flex' : 'block',
                                                    textAlign: (participants.length === 0) ? 'center' : 'initial',
                                                    justifyContent: (participants.length === 0) ? 'center' : 'initial',
                                                    alignItems: (participants.length === 0) ? 'center' : 'initial',
                                                }}
                                            >
                                                {participants.length === 0 ? 
                                                    <Typography>Parece que esta clase está vacía, los usuarios se pueden anotar por su cuenta o puedes hacerlo tú mism@ eligiendo los clientes del lado izquierdo, Nota: No se descontarán créditos si se hace de manera manual</Typography>
                                                :
                                                <>
                                                <Typography>{`${participants.length + invitees.length} / ${classCapacity} lugares utilizados`}</Typography>
                                                {
                                                    (invitees.length > 0) && invitees.map((invitee) => (
                                                        <NameInvitee invitee={invitee}/>
                                                    ))  
                                                }
                                                </>
                                                }
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                </CustomTabPanel>
                            </SwipeableViews>
                        </Grid>
                        {/* Cancel and add participants buttons */}
                        <Grid item xs={1} textAlign={'end'}>
                            <Button variant="outlined" onClick={handleClose} sx={{marginRight: '20px'}}>Cancelar</Button>
                            <Button variant="contained" sx={{color: '#FFF'}} onClick={updateParticipants}>Guardar</Button>
                        </Grid>
                    </Grid>
                    
                </Box>
            </Fade>
        </Modal>
    );
};

export default PeopleModal;