import React, { useEffect, useState } from "react";
import { 
    Grid, 
    Typography, 
    useTheme, 
    Box, 
    Paper, 
    CircularProgress, 
    styled, 
    ToggleButtonGroup, 
    ToggleButton, 
    toggleButtonGroupClasses,
    Divider,
    Grow
} from "@mui/material";
import PackCard from "../PackCard/PackCard.jsx";
import CheckoutModal from "./CheckoutModal.jsx";
import axios from 'axios';
import AuraCard from "./AuraCard.jsx";

export default function MyWallet (props) {
    const [packs, setPacks] = useState([]);
    const [selectedPack, setSelectedPack] = useState(null);
    const [packType, setPackType] = React.useState(2); // <--- 0 is for sculpt/tone, 1 is for pilates reformer & sculpt/tone, 2 is for all.
    const [filteredPacks, setFilteredPacks] = React.useState([]);
    const [loading, setLoading] = useState(false);

    // Custom ToggleButton
    const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
        [`& .${toggleButtonGroupClasses.grouped}`]: {
            margin: theme.spacing(0.3),
            border: 1,
            [`&.${toggleButtonGroupClasses.disabled}`]: {
                border: 1,
                borderRadius: 0,
            },
            [`&.${toggleButtonGroupClasses.selected}`]: {
                border: 1,
                borderRadius: 10,
                backgroundColor: theme.palette.primary.main,
                '&:hover': {
                    backgroundColor: theme.palette.primary.main, // Keep the same background color on hover
                },
            },
            '&:hover': {
                backgroundColor: 'unset', // Disable hover color changing
            },
        },
        [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]:
            {
                borderLeft: '1px solid transparent',
                color: theme.palette.text.primary,
            },
    }));

    // Get packs from the DB
    useEffect(() => {
        getPacks();
        sessionStorage.setItem('actionPerformed', 'false');
    }, []);

    // Hear for changes of classType to filter classes
    useEffect(() => {
        filterPacks(packType);
    }, [packType, packs]);

    // Function to filter classes by type
    const filterPacks = (type) => {
        if (type === 2) {
            setFilteredPacks(packs);
        } else if (type === 1) {
            setFilteredPacks(packs.filter((classItem) => classItem.discipline.includes('sculpt_tone')));
        } else if (type === 0) {
            setFilteredPacks(packs.filter((classItem) => classItem.discipline.includes('pilates_reformer')));
        }
    };

    // Get packs from the DB
    const getPacks = async () => {
        setLoading(true);
        await axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/pack/getAll`)
        .then((response) => {
            setPacks(response.data.result);
            setLoading(false);
        })
        .catch((error) => {
            console.log(error);
            setLoading(false);
        });
    }

    // Import theme
    const theme = useTheme();

    return (
        <Grid container direction='column'>
            <CheckoutModal 
                selectedPack={selectedPack} 
                setSelectedPack={setSelectedPack} 
                logguedInUser={props.logguedInUser} 
                setLogguedInUser={props.setLogguedInUser}
                purchaseCompleted={props.purchaseCompleted}
                loadStripe={props.loadStripe}
            />
            <Typography sx={{fontFamily: 'PangramBold', fontSize: '15px', color: props.darkMode ? '#FFF' : '#000', opacity: 0.6, textAlign: 'left', p: 1}}>Tu tarjeta virtual</Typography>
            {/* Blance card */}
            <Grid item xs={5} alignSelf={'center'} sx={{p: 1}}>
                <AuraCard logguedInUser={props.logguedInUser} darkMode={props.darkMode}/>
            </Grid>
            {/* Top up title */}
            <Grid item xs={1} textAlign={'left'} sx={{p: 1, mt: 1}}>
                <Typography sx={{fontFamily: 'PangramBold', fontSize: '15px', color: props.darkMode ? '#FFF' : '#000', opacity: 0.6}}>Quick top-up</Typography>
            </Grid>
            {/* Pack cards section*/}
            <Grid item xs={6} sx={{width: {xs: '100%', md: '60%'}, mb: '100px'}} alignSelf={'center'}>
                {/* Selector of pack type */}
                <Box
                        sx={{
                            m: 1
                        }}
                    >
                        <StyledToggleButtonGroup
                            value={packType}
                            exclusive
                            fullWidth
                            onChange={(event, newAlignment) => {
                                if (newAlignment !== null) {
                                    setPackType(newAlignment);
                                }
                            }}
                            aria-label="text alignment"
                            sx={{
                                margin: 0, 
                                backgroundColor: theme.palette.mode == 'light' ? theme.palette.grey[300] : theme.palette.background.paper, 
                                borderRadius: '15px !important'
                            }}
                        >
                            <ToggleButton value={0} sx={{borderRadius: '0px', textTransform: 'none', lineHeight: 1}}>Pilates Reformer</ToggleButton>
                            <Divider orientation="vertical" flexItem sx={{borderWidth: '1px', my: 1}}/>
                            <ToggleButton value={1} sx={{borderRadius: '0px', textTransform: 'none'}}>Sculpt/Tone</ToggleButton>
                            <Divider orientation="vertical" flexItem sx={{borderWidth: '1px', my: 1}}/>
                            <ToggleButton value={2} sx={{borderRadius: '0px', textTransform: 'none'}}>Todo</ToggleButton>
                        </StyledToggleButtonGroup>
                    </Box>
                {/* Pack cards */}
                <Grid container direction='row' sx={{justifyContent: 'center', p: {xs: 1, md: 2}}} spacing={{xs: 2, md: 3}}>
                    {
                        !loading ?
                            filteredPacks.length > 0 ? (
                                filteredPacks.map((pack, index) => (
                                    <Grow in={true} style={{ transitionDelay: `${50 * index}ms` }}>
                                        <Grid item xs minWidth={'300px'}>
                                            <PackCard key={index} pack={pack} setSelectedPack={setSelectedPack}/>
                                        </Grid>
                                    </Grow>
                                ))
                            ) : (
                                <Typography sx={{fontFamily: 'PangramBold', fontSize: '15px', color: props.darkMode ? '#FFF' : '#000', opacity: 0.6, textAlign: 'center', p: 2, ml: 2}}>
                                    Lo sentimos no hay paquetes disponibles para esta categoría aún, por favor prueba con otra.
                                </Typography>
                            )
                        :
                        <CircularProgress size={60} sx={{m: 2}}/>
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}