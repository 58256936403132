import React, { useState, useEffect } from "react";
import { Grid, Typography, Button, useTheme, Table, TableBody, TableRow, TableCell, Box } from "@mui/material";
import { colors } from "../../themes/themeCons";
import CIcon from '@coreui/icons-react';
import * as icon from '@coreui/icons';

export default function PackCard (props) {
    const [isTouchDevice, setIsTouchDevice] = useState(false);
    // Use theme
    const theme = useTheme();

    useEffect(() => {
        isTouch();
    }, []);

    // Obtain displayValue from schema to display pack value
    const displayValue = (value) => {
        if ((value.includes('pilates_reformer') && value.includes('sculpt_tone')) || value.includes('all')) {
            return 'Disciplinas: Pilates Reformer, Sculpt & Tone';
        } else if (value.includes('pilates_reformer')) {
            return 'Disciplina: Pilates Reformer';
        } else if (value.includes('sculpt_tone')) {
            return 'Disciplina: Sculpt & Tone';
        } else {
            return 'Paquete';
        }
    }

    // Check if device is touch
    const isTouch = () => {
        if (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0)) {
            setIsTouchDevice(true);
        }
    }

    // RETURN
    return (
        <Button 
            variant='contained' 
            sx={{
                backgroundColor: theme.palette.background.paper, 
                color: '#000', 
                fontFamily: 'PangramRegular', 
                borderRadius: '10px', 
                fontSize: '20px',
                alignItems: 'flex-start',
                textTransform: 'none',
                transition: 'transform 0.2s',
                width: '100%',
                '&:hover': { 
                    transform: isTouchDevice ? 'none' : 'scale(1.05)',
                    backgroundColor: isTouchDevice ? theme.palette.background.paper : colors.fourthary,
                },
                mb: 1
            }}
            onClick={() => props.setSelectedPack(props.pack)}
        >
            <Grid container direction='column'>
                <Grid item xs>
                    <Typography 
                        sx={{
                            color: theme.palette.mode == 'dark' ? theme.palette.text.primary : colors.primary,
                            fontFamily: 'PangramBold',
                            alignContent: 'center',
                        }}
                    >{displayValue(props.pack.discipline)}</Typography>
                </Grid>
                <Grid item xs>
                    <Typography sx={{fontSize: '12px', color: theme.palette.text.primary}}>{props.pack.description}</Typography>
                </Grid>
                <Grid item xs alignSelf={'center'} sx={{mt:1, mb:1}}>
                    <Typography 
                        sx={{
                            fontSize: '14px', 
                            color: '#FFF',
                            backgroundColor: theme.palette.primary.main,
                            width: 'fit-content',
                            pt:0.5,
                            pr: 1,
                            pl: 1,
                            pb: 0.5,
                            borderRadius: '30px',
                            fontWeight: 'bold',
                        }}
                    >
                        {props.pack.packType === 'regular' ? 'Paquete para ti 🫶🏻' : 'Paquete para invitar amig@s 👭'}
                    </Typography>
                </Grid>
                <Grid item xs>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{p: 0.5}} align='left'>{'Clases'}</TableCell>
                                <TableCell sx={{p: 0.5}} align='right'>{props.pack.classes}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{p: 0.5}} align='left'>{'Validez'}</TableCell>
                                <TableCell sx={{p: 0.5}} align='right'>{`${props.pack.validityDays} días`}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item xs>
                    <Typography
                        sx={{
                            color: theme.palette.text.primary,
                            fontSize: '20px',
                            fontFamily: 'PangramBold',
                            mt: '10px',
                            mb: '50px'
                        }}
                    >{`$${props.pack.priceMXN} MXN`}</Typography>
                </Grid>
            </Grid>
            
            
            <Box
                sx={{
                    backgroundImage: `linear-gradient(to right, ${colors.primary}, ${colors.tertiary})`,
                    width: '50px',
                    height: '50px',
                    position: 'absolute',
                    top: '90%',
                    alignContent: 'center',
                    borderRadius: '10px',
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
                }}
            >
                <CIcon 
                    icon={icon.cilDiamond} 
                    className='text-primary' 
                    style={{height: '20px', color: '#000', opacity: 0.7}}
                />
            </Box>
        </Button>
    )
}