    // Function to compress the image and convert it to base64 format
    const compressImage = (file) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (event) => {
                const img = new Image();
                img.src = event.target.result;
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    const maxWidth = 350;
                    const maxHeight = 350;
                    let width = img.width;
                    let height = img.height;

                    if (width > height) {
                        if (width > maxWidth) {
                            height *= maxWidth / width;
                            width = maxWidth;
                        }
                    } else {
                        if (height > maxHeight) {
                            width *= maxHeight / height;
                            height = maxHeight;
                        }
                    }

                    canvas.width = width;
                    canvas.height = height;
                    ctx.drawImage(img, 0, 0, width, height);

                    const compressedBase64 = canvas.toDataURL('image/jpeg', 0.9);
                    // Do something with the compressed image in base64 format
                    resolve(compressedBase64);
                };
            };
        });
    }

    // Convert ISO Date to local Date with date and time
    const convertISODateToLocalDate = (isoDateString) => {
        const date = new Date(isoDateString);
        const localDate = new Date(date.getTime() + date.getTimezoneOffset());
        return localDate;
    }

    // Convert date object to mexico city timezone
    const convertDateToMexicoCityTimezone = (date) => {
        const mexicoCityTimezone = 'America/Mexico_City';
        return new Date(date.toLocaleString('en-US', { timeZone: mexicoCityTimezone }));
    }

    // Convert Central Standard Time to UTC for saving in database
    const convertToUTCString = (date) => {
        const utcDate = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(),
        date.getUTCDate(), date.getUTCHours(),
        date.getUTCMinutes(), date.getUTCSeconds());
        return new Date(utcDate).toISOString();
    }

    // Format UTC to CST date return format dd/mm/yyyy
    let formatUTCDate = (date) => {
        const dateObj = convertDateToMexicoCityTimezone(new Date(date));
        const day = dateObj.getDate().toString().padStart(2, '0');
        const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
        const year = dateObj.getFullYear();
        return `${day}/${month}/${year}`;
    }

    // Format UTC to CST time return format hh:mm AM/PM
    let formatUTCTime = (date) => {
        const dateObj = convertDateToMexicoCityTimezone(new Date(date));
        let hours = dateObj.getHours();
        let minutes = dateObj.getMinutes();
        let period = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;
        return `${hours}:${minutes.toString().padStart(2, '0')} ${period}`;
    }

export { 
    compressImage, 
    convertISODateToLocalDate, 
    convertDateToMexicoCityTimezone, 
    convertToUTCString,
    formatUTCDate,
    formatUTCTime
};