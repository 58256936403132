import React, { useState, useEffect } from 'react';
import { 
    Modal, 
    Backdrop, 
    Fade, 
    Box, 
    Grid, 
    IconButton, 
    Typography,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { convertISODateToLocalDate } from '../../helpers/functions';


const CancellationsModal = (props) => {
    const [cancellators, setCancellators] = useState([]);

    // Use effect obtain all available users
    useEffect(() => {
        if (props.cancellations != null || props.cancellations != undefined) {
            setCancellators([]);
            getNamesOfCancellators();
        }
    }, [props.cancellations]);

    // Get names of cancellators
    let getNamesOfCancellators = async () => {
        let cancellators = await fetchNamesOfCancellators(props.cancellations);
        setCancellators(cancellators);
    }

    // Fetch names of cancellators Promise
    let fetchNamesOfCancellators = async (cancellators) => {
        return new Promise((resolve, reject) => {
            let tempCancellators = [];
            cancellators.forEach(async (cancellator) => {
                let response = await axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/user/find`, { userID: cancellator.participantID });
                let cancellationDate = new Date(cancellator.date);

                tempCancellators.push({
                    date: convertISODateToLocalDate(cancellationDate).toLocaleDateString(),
                    time: convertISODateToLocalDate(cancellationDate).toLocaleTimeString(),
                    name: response.data.result[0].firstName,
                    lastname: response.data.result[0].lastName
                });
                if (tempCancellators.length === cancellators.length) {
                    resolve(tempCancellators);
                }
            });
        });
    }

    // Handle modal close
    const handleClose = () => {
        props.setCancellations(null);
    };

    return (
        <Modal
            open={props.cancellations != null}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
        >
            <Fade in={props.cancellations != null}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 2,
                    width: {xs: '100vw', sm: '90vw', md: '80vw'},
                    height: {xs: '100vh', sm: '90vh', md: '80vh'},
                    overflowY: 'auto'
                }}>
                    <Grid 
                        container 
                        spacing={0} 
                        direction='column'
                        display={'flex'}
                        height={'100%'}
                    >
                        {/* Title and close button */}
                        <Grid item xs={1}>
                            <Grid container direction={'row'}>
                                <Grid item xs>
                                    <Typography variant="h6">Cancelaciones de la clase</Typography>
                                </Grid>
                                <Grid item>
                                    <IconButton variant="contained" onClick={handleClose}>
                                        <CloseIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* Participants */}
                        <Grid item xs={11} sx={{overflow: 'scroll'}}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Fecha</TableCell>
                                        <TableCell>Hora</TableCell>
                                        <TableCell>Nombre</TableCell>
                                        <TableCell>Apellido</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {cancellators.length === 0 ? (
                                        <TableRow>
                                            <TableCell align="center" colSpan={3}>
                                                No hay cancelaciones registradas
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        cancellators.map((cancellator, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{cancellator.date}</TableCell>
                                                <TableCell>{cancellator.time}</TableCell>
                                                <TableCell>{cancellator.name}</TableCell>
                                                <TableCell>{cancellator.lastname}</TableCell>
                                            </TableRow>
                                        ))
                                    )}
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>
                    
                </Box>
            </Fade>
        </Modal>
    );
};

export default CancellationsModal;